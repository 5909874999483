<script setup>
 // Composables
 import { getEnv } from '@/composables/helpers/getEnv';

 const props = defineProps({
  anchorId: String,
  headline: String,
  buttonLabel: String,
  tours: Array,
  blockIndex: Number,
  buttonOverlap: {
   type: Boolean,
   default: true,
  },
 });

 //  Variables
 const { headline, buttonLabel, tours } = toRefs(props);

 // Content Variables
 const apiKey = getEnv('builderApi');
 const builderEnv = getEnv('builderEnv');

 const postMeta = useState('postMeta');

 const cards = ref([]);
 const IDs = ref({
  include: [],
  exclude: [],
 });

 if (postMeta.value?.id) {
  IDs.value.exclude = [postMeta.value?.id];
 }

 for (const index in tours.value) {
  const tour = tours.value[index].tour.id;
  IDs.value.include.push(tour);
 }

 const cacheDuration = getEnv('cacheDuration', true) || 900;

 const { data: cityToursData } = await useAsyncData('city-tours' + postMeta.value?.id + props.blockIndex, () =>
  $fetch('https://cdn.builder.io/api/v3/content/tours', {
   params: {
    apiKey,
    'query.data.environment': builderEnv,
    'query.id.$in': JSON.stringify(IDs.value.include),
    'query.id.$nin': JSON.stringify(IDs.value.exclude),
    limit: 100,
    cacheSeconds: cacheDuration,
   },
  })
 );

 if (cityToursData.value && cityToursData.value.results) {
  for (const index in cityToursData.value.results) {
   // Grab Tour
   const tour = cityToursData.value.results[index];
   // Move ID from include to exclude
   if (IDs.value.include.includes(tour.id)) {
    const indexOf = IDs.value.include.indexOf(tour.id);
    IDs.value.include.splice(indexOf, 1);
    IDs.value.exclude.push(tour.id);
   }
   // Push to cards
   cards.value.push(tour);
  }
 }

 const randomNumbers = useState('random-numbers' + postMeta.value?.id + props.blockIndex, () => []);

 if (cards.value.length < 4) {
  const { data: backfillDataQuery } = await useAsyncData('backfill' + postMeta.value?.id + props.blockIndex, () =>
   $fetch('https://cdn.builder.io/api/v3/content/tours', {
    params: {
     apiKey,
     'query.data.environment': builderEnv,
     'query.id.$nin': JSON.stringify(IDs.value.exclude),
     //  limit: 4 - cards.value.length,
     limit: 100,
     cacheSeconds: cacheDuration,
    },
   })
  );

  // Generate random numbers
  if (
   !randomNumbers.value.length &&
   backfillDataQuery.value &&
   backfillDataQuery.value.results &&
   backfillDataQuery.value.results.length
  ) {
   for (let i = 0; i < 4 - cards.value.length; i++) {
    let random = Math.floor(Math.random() * backfillDataQuery.value.results.length);
    while (randomNumbers.value.includes(random)) {
     random = Math.floor(Math.random() * backfillDataQuery.value.results.length);
    }
    randomNumbers.value.push(random);
   }
  }

  // Update backfillData
  for (const index in randomNumbers.value) {
   const tour = backfillDataQuery.value.results[randomNumbers.value[index]];
   cards.value.push(tour);
  }
 }

 // Methods
 const makeTag = (tour) => {
  if (!tour) return false;

  const type = tour.data?.filters?.tourType || tour.data?.filters?.tourType;
  const popular = tour.popular || tour.data?.popular;

  if (!type && !popular) return false;

  let tag = '';

  if (type) tag = type;
  if (type && popular) tag += ' • ';
  if (popular) tag += '<span>our most popular tour!</span>';

  return tag;
 };
</script>
<template>
 <section :id="anchorId" class="block block-city-tours-manual">
  <div class="container">
   <div class="small-container container" :data-overlap="buttonOverlap ? true : 'false'">
    <ProjectTitle v-if="headline" :title="headline" />
    <ProjectButton v-if="buttonLabel" :label="buttonLabel" link="/city-tours" />
   </div>
   <ul class="tours">
    <li v-for="tour in cards" class="tour" :key="tour.id">
     <MessLink v-if="tour.data.url" :href="tour.data.url">
      <div class="media">
       <MessImage v-if="tour.data?.gallery && tour.data?.gallery.length" :image="tour.data?.gallery[0]" />
       <ProjectPill v-if="makeTag(tour)" :text="makeTag(tour)" class="image-tag"
        ><span v-html="makeTag(tour)" />
       </ProjectPill>
      </div>
      <div class="content">
       <MessHtml v-if="tour.data?.title" :html="tour.data?.title" tag="h3" class="title h4" />
       <MessHtml v-if="tour.data?.description" :html="tour.data?.description" class="copy" />
      </div>
     </MessLink>
    </li>
   </ul>
  </div>
 </section>
</template>

<style lang="scss">
 .block-city-tours-manual {
  .container {
   .small-container {
    max-width: 1130px;
    position: relative;

    &[data-overlap='true'] {
     .mess-button {
      position: absolute;
      right: 0;
      bottom: 65px;
     }
    }
    &[data-overlap='false'] {
     .mess-button {
      margin-bottom: 4rem;
     }
    }
   }

   .tours {
    display: flex;
    gap: 1rem;

    .tour {
     flex: 1;
     a {
      .media {
       position: relative;

       .pill {
        --offset: 1rem;
        position: absolute;
        max-width: calc(100% - (var(--offset) * 2));
        top: var(--offset);
        left: var(--offset);

        span {
         color: var(--red, #c00);
         text-align: center;
         font-size: 12px;
         font-style: normal;
         font-weight: 900;
         line-height: 123.1%; /* 14.772px */
         letter-spacing: 1.38px;
         text-transform: uppercase;
        }
       }
       .mess-image {
        aspect-ratio: 11/8;
        filter: grayscale(40%);
        transition: var(--transition);
       }
      }

      .content {
       padding-top: 1.5rem;
       background: white;
       transition: var(--transition);
       h4 {
        margin-top: 1.5rem;
       }
       .button-tickets-times {
        margin-top: 1.5rem;
       }
       .departures {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        margin-top: 1rem;

        .expired {
         border-color: var(--gray-300);
         background: var(--gray-300);
         color: var(--gray-700);
        }
       }
      }

      &:hover {
       .mess-image {
        filter: grayscale(100%);
       }
       .content {
        background: white;
        transform: translateY(-3rem);
       }
      }
     }
    }
   }
  }

  @media (max-width: $tablet) {
   .container {
    .small-container {
     h2 {
      font-size: 5rem;
      line-height: 1.2;
     }
     &[data-overlap='true'] {
      .mess-button {
       bottom: 40px;
      }
     }
    }

    .tours {
     flex-wrap: wrap;

     .tour {
      width: calc(50% - 0.5rem);
      flex: none;
     }
    }
   }
  }
  @media (max-width: $mobile) {
   .container {
    .small-container {
     h2 {
      font-size: 4rem;
      line-height: 1.2;
     }
     &[data-overlap='true'] {
      .mess-button {
       position: static;
       margin-bottom: 4rem;
      }
     }
    }

    .tours {
     flex-direction: column;
     gap: 3rem;

     .tour {
      flex: 1;
      width: 100%;
     }
    }
   }
  }
 }
</style>
